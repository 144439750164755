import { forwardRef, useContext, useRef, useState } from 'react';
/* eslint-disable @nx/enforce-module-boundaries */
import { Text } from '@mybridge/ui/text';
import { getUserFullName, getUserFirstName } from 'v4/lib/commons';
import { Box, Image, Link, Popover } from '@mybridge/ui';
import { InsightPopover } from 'v4/components/post/insight-popover';
import { useSelector } from 'react-redux';
import { UserDetailsPopover } from 'v4/components/user-details-popover';
import { Avatar, Divider, Flex, HStack, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { ChatOutLined, HandShakeSimpleIcon, SimpleCloseIcon } from '@mybridge/icons';
import { MyNetworkContext } from 'v4/components/network/context';
import { useRouter } from 'next/router';

export const PostFeelingAndTagsText = forwardRef(
  (
    { feeling, tagged, imgProps, companyDetails,fromCreate=false, ...props },
    ref
  ) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { 
    isFriend,
    handshakeUser,
    followUser,
    isHandshakeRequested,
    isFollowed,
    handshakeLoading,
    handleHandshakeSendRevoke, 
  } =
    useContext(MyNetworkContext);
  
  const router = useRouter();

  const [loadingButtonId, setLoadingButtonId] = useState(null);

  const triggerRef = useRef(null);

// Function to calculate position
const getPopupPosition = () => {
  if (!triggerRef.current) return {};
  
  const triggerRect = triggerRef.current.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const spaceBelow = viewportHeight - triggerRect.bottom;
  const spaceAbove = triggerRect.top;
  
  // If more space below, show popup below
  if (spaceBelow >= 400 || spaceBelow > spaceAbove) {
    return {
      top: triggerRect.bottom + 8,
      left: triggerRect.left
    };
  }
  
  // Show popup above if more space there
  return {
    bottom: viewportHeight - triggerRect.top + 8,
    left: triggerRect.left
  };
};

  // Modify the handshake handler
  const handleHandshake = async (user) => {
    setLoadingButtonId(user.id);
    try {
      await handshakeUser?.(user);
    } finally {
      setLoadingButtonId(null);
    }
  };
  
  const timerRef = useRef(null);

  const handleMouseEnter = () => {
    if (tagged?.length > 1) {
      clearTimeout(timerRef.current);
      setIsPopupOpen(true);
    }
  };
  
  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setIsPopupOpen(false);
    }, 1000);
  };

    return (
      <>
        {/* Display the user's name */}
        {feeling?.name&&fromCreate &&(<Text display="inline" color="brandPrimary.500" fontWeight="medium">
          {companyDetails ? companyDetails?.name : userProfileInfo?.display_name}
        </Text>)}

        {/* Display the feeling if available */}
        {feeling?.name && (
          <>
            <Text color="brandPrimary.500" display="inline">
              {' is '}
            </Text>
            <Image
              mb={'-1'}
              display="inline"
              src={feeling?.file}
              w="6"
              h="6"
              {...imgProps}
            />
            <Text color="brandPrimary.500" display="inline">
              {` ${feeling?.type?.toLowerCase?.()} ${feeling?.name}`}
            </Text>
          </>
        )}

        {/* Display tagged users if available */}
        {tagged?.length ? (
          <>
            {/* Avoid repeating the name if there's no feeling */}
            {!feeling?.name && (
              <>
                <Text display="inline" color="brandPrimary.500" fontWeight="medium">
                  {companyDetails ? companyDetails?.name : userProfileInfo?.display_name}
                </Text>  
                </>)}
                <Text
                  color="#3D5A80"
                  display="inline"
                  style={{
                    fontSize: '15px',
                    fontWeight: '300',
                    lineHeight: '120%',
                  }}
                >
                   {!feeling && !feeling?.name ? ' is with ' : ' with '}
                </Text>
          
            
            {/* Display the first tagged user */}
            <UserDetailsPopover user={tagged?.[0]}>
            <Text
              as={Link}
              href={'/p/' + tagged?.[0]?.slug}
              target="_blank"
              fontWeight="500"
              color="#3D5A80"
              display="inline"
              fontSize="15px"
            >
              {` ${getUserFirstName(tagged?.[0])}`}
            </Text>
            </UserDetailsPopover>
            {/* If more than one tagged user, show a popover with others */}
            {tagged?.length > 1 && <Text display="inline">{' and '}</Text>}
            {tagged?.length > 1 && (
              <InsightPopover
                triggerProps={{ display: 'inline' }}
                content={
                  <>
                    {tagged?.slice?.(1)?.map?.((t, tIndex) => (
                      <Text color="white" key={tIndex}>
                        {getUserFirstName(t)}
                      </Text>
                    ))}
                  </>
                }
              >
                <Text
                  ref={triggerRef}
                  textDecoration="underline"
                  color="brandPrimary.500"
                  display="inline"
                  fontWeight="500"
                  fontSize="15px"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  {`${tagged?.length - 1} other${tagged?.length > 2 ? 's' : ''}`}
                </Text>
              </InsightPopover>
            )}
          </>
        ) : (
          ``
        )}
        {isPopupOpen && (
          <Box
            position="fixed"
            w="400px"
            bg="white"
            style={getPopupPosition()}
            borderRadius="8px"
            boxShadow = "0px 0px 10px 0px #00000033"
            p={4}
            pt={0}
            zIndex={10}
            minHeight="400px"
            onMouseEnter={() => clearTimeout(timerRef.current)}
            onMouseLeave={handleMouseLeave}
          >
            
            <Box position="relative" border="transparent">
            <Divider mb={4} border="transparent"/>
            { 
            <Box>
              <Flex justifyContent="space-between" alignItems="center" mb={4}>
                <Text fontWeight="400" fontSize="20px" color="#3D5A80" display="flex" justifyContent="center" width="100%">
                  Tagged Users
                </Text>
                <IconButton
                  display={"flex"}
                  icon={<SimpleCloseIcon height="14px" width="14px" />}
                  aria-label="Close"
                  variant={'unstyled'}
                  onClick={() => setIsPopupOpen(false)}
                />
              </Flex>
              <Box>
              <VStack spacing={4} align="stretch">
                    {
                      tagged.map((user, index) => (
                        <Flex key={index} alignItems="center" justifyContent="space-between">
                          <HStack spacing={3}>
                            <Avatar src={user?.profile_pic} name={getUserFullName(user)} />
                            <Text fontWeight="500" color="#5B5B5B" fontSize="15px">{getUserFullName(user)}</Text>
                          </HStack>
                          <HStack spacing={2}>
                          {userProfileInfo?.id === user?.id ? null :
                          !isFriend(user) ? <HStack>
                            {isHandshakeRequested?.(user) ? (
                              <Tooltip label="Withdraw Handshake">
                                <IconButton
                                  onClick={(e) =>
                                    handleHandshakeSendRevoke?.(user)
                                  }
                                  _hover={{ bg: 'gray.100' }}
                                  isLoading={handshakeLoading}
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#F5AEAD"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                  w={'41px'}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  
                                  variant="none"
                                />

                              </Tooltip>
                            ) : (
                              <Tooltip zIndex="99999999" label="Send Handshake Request">
                                <IconButton
                                  w={'41px'}
                                  _hover={{ bg: 'gray.100' }}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  variant="none"
                                  // onClick={(e) => handshakeUser?.(user)}
                                  // isLoading={handshakeLoading}
                                  onClick={() => handleHandshake(user)}
                                  isLoading={loadingButtonId === user.id}
                                  // w="70px"
                                  // isDisabled={isHandshakeRequested?.(user)}
                                  icon={
                                    <HandShakeSimpleIcon
                                      color="#94B096"
                                      width="20"
                                      height="20"
                                    />
                                  }
                                />
                              </Tooltip>
                            )}
                          </HStack> :
                          <Box
                          w={'41px'}
                          height={'41px'}
                          border={'1px solid #00000033'}
                          cursor="pointer"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius={'50%'}
                          onClick={(e) => {
                            router.push(`/profile/message?user=${user?.matrix_user_id}`)
                          }}
                          _hover={{ bg: 'gray.100' }}
                        >
                          <ChatOutLined/>
                        </Box>
                          }
                          </HStack>
                        </Flex>
                      ))}
                  </VStack>
              </Box>
            </Box>
          
            }
            </Box>
          </Box>
        )}
      </>
    );
  }
);
